import { createContext, useContext, cloneElement } from 'react';
import { flow, isEmpty } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { withAppSettings } from 'src/AppSettings';
import { allowListPaths } from 'src/routes/paths';
import { OrganizationPlgType } from 'src/generated/gql/graphql';
import { EV_ORGANIZATION_ID } from 'src/Auth/common';

import { checkForExistingActiveConnection, getUser } from './queries';
import Auth from './Auth/Auth';

export const GlobalContext = createContext({});

export const withGlobalContext = component => {
  const Component = component;

  return props => {
    const settings = useContext(GlobalContext);
    return <Component {...props} globalContext={settings} />;
  };
};

export const useGlobalContext = () => useContext(GlobalContext);

export const getIsTeamsEnabled = role => {
  return !!role?.toLowerCase()?.includes('team');
};

const getOrgTypes = appSettings => {
  const plgConfig = appSettings?.organizationPlgConfig;
  const isPlg = !!plgConfig;

  const isWorkatoConnect =
    plgConfig?.type === OrganizationPlgType.MortgageTotalExpert;

  return {
    isPlg,
    isWorkatoConnect
  };
};

const GlobalContextProvider = ({
  children,
  location: { pathname },
  appSettings,
  getUser = { me: {}, us: {}, myOrganization: {} }
}) => {
  const { loading, error, refetch, me, us, myOrganization } = getUser;

  const orgTypes = getOrgTypes(appSettings);

  const checkForWorkatoConnectionResult = useQuery(
    checkForExistingActiveConnection,
    {
      fetchPolicy: 'no-cache',
      skip: !orgTypes.isWorkatoConnect || isEmpty(me)
    }
  );

  const workatoConnection =
    checkForWorkatoConnectionResult?.data?.checkForExistingActiveConnection;

  const offices = (me?.availableGroups || [])?.map(group => ({
    ...group,
    isTeamsEnabled: getIsTeamsEnabled(us?.role)
  }));
  const officeId = us?.id || null;
  const selectedOffice = offices?.find(obj => obj.id === officeId) || {};
  const officeName = selectedOffice?.name || 'office';
  const isTeamsEnabled = getIsTeamsEnabled(us?.role);

  const appPermissions = {
    isEvocalizeOrg: myOrganization?.id === EV_ORGANIZATION_ID
  };

  // The options parameter being passed to the getUser refetch method has been added
  // to be backward compatible with the previous implementation of the refetch method
  // which called the getUser refetch directly instead of a combined refetch function.
  const refetchUser = options => {
    refetch(options);

    if (orgTypes.isWorkatoConnect) {
      checkForWorkatoConnectionResult.refetch();
    }
  };

  const globalContext = {
    me,
    us,
    myOrganization,
    appPermissions,
    architectures: us?.architectures,
    office: {
      id: officeId,
      offices,
      officeName,
      selectedOffice,
      isTeamsEnabled
    },
    isAllowListPath: allowListPaths.includes(pathname),
    loading,
    refetch: refetchUser,
    error: error || checkForWorkatoConnectionResult.error,
    orgTypes,
    ...(orgTypes.isWorkatoConnect && { workatoConnection })
  };

  return (
    <GlobalContext.Provider value={globalContext}>
      {cloneElement(children, {
        globalContext
      })}
    </GlobalContext.Provider>
  );
};

export default flow(
  graphql(getUser, {
    name: 'getUser',
    options: props => ({
      variables: {
        ...(props?.appSettings?.app?.features?.showInactiveBlueprints && {
          showInactive: true
        })
      }
    }),
    skip: ({ location: { pathname } }) => {
      if (
        allowListPaths.filter(url => pathname === url).length ||
        !Auth.isAuthenticated()
      ) {
        // Note: skip the me query if at an allowed url so we can show unauthed pages
        //       like the activation page
        return true;
      }
    }
  }),
  withRouter,
  withAppSettings
)(GlobalContextProvider);
